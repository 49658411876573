<template>
  <div v-if="isloading">
    <h1>Loading...</h1>
  </div>
  <div v-else class="post d-flex flex-column-fluid" id="kt_post">
    <div v-if="token" id="kt_content_container" class="container-xxl py-8">
      <div class="">
        <h3 class="m-0 title-green">Riwayat Perubahan Data</h3>
        <hr class="m-0 title-green horizontal-line" />
        <HistoryStep :historyData="historyData" />
      </div>

      <div class="d-grid gap-4">
        <div class="row text-center px-5">
          <div class="col-6 d-grid px-1">
            <button class="btn btn-warning btn-lg" @click="toPersonalEdit">
              Ubah Data Pribadi
            </button>
          </div>
          <div class="col-6 d-grid px-1">
            <button
              class="btn btn-warning btn-lg"
              @click="toPersonalFamilyEdit"
            >
              Ubah Data Keluarga
            </button>
          </div>
        </div>
        <div class="row text-center px-6">
          <button
            @click="$router.back()"
            class="btn btn-secondary btn-lg btn-block px-5"
          >
            Kembali
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-danger mb-10" role="alert">
        Akses expired, silahkan kembali ke beranda terlebih dahulu
      </div>
    </div>
  </div>
</template>
<script>
import HistoryStep from "../../../components/mobile/HistoryStep.vue";
import BaseService from "../../../services/base.service";

export default {
  components: {
    HistoryStep,
  },
  data() {
    return {
      isloading: true,
      token: null,
      historyData: null,
    };
  },
  methods: {
    async init() {
      try {
        const { token } = this.$route.params;
        const personData = new BaseService("services/history", token);
        const { data } = await personData.getListData();
        this.historyData = data;
        this.token = token;
        this.isloading = false;
        console.log(data.history);
      } catch (error) {
        this.isloading = false;
        console.log(error);
      } finally {
        this.isloading = false;
        console.log("ok");
      }
    },
    toPersonalEdit() {
      const { token } = this.$route.query;
      this.$router.push({
        name: "service-personal-edit",
        query: { token },
      });
    },
    toPersonalFamilyEdit() {
      const { token } = this.$route.query;
      this.$router.push({
        name: "service-personal-edit-family",
        query: { token },
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.btn-warning {
  background-color: #e56e00 !important;
}
</style>
